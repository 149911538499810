import { jwtDecode } from 'jwt-decode';
import { call, put, select } from 'typed-redux-saga';
import { push } from 'redux-first-history';
import {
  selectRefreshToken,
  selectRefreshLock,
  selectRefreshLockTimestamp,
} from '@integration-frontends/common/auth/core/application';

import { DI_CONTAINER, getObservabilityService } from '@integration-frontends/core';
import {
  AUTHENTICATE_SERVICE_TOKEN,
  IAuthenticateService,
  IDENTITY_STORE_TOKEN,
  IIdentityStore,
} from '@integration-frontends/common/auth/core/model';
import {
  clear,
  loginError,
  setIdentity,
  setRefreshLock,
  setRefreshToken,
} from 'libs/common/auth/core/application/src/lib/actions';
import {
  IOauthService,
  OAUTH_SERVICE_TOKEN,
} from '@integration-frontends/workflow-manager/core/model';

interface decodedApiKeyProperties {
  exp?: number;
}
type Fn = (...args: any[]) => any;

const SIXTY_SECONDS = 60;
const REFRESH_LOCK_TTL_MILLISECONDS = 5000;

export function* callWithTokenRefresh(fn: Fn, ...args: any[]) {
  let refreshLock = yield select(selectRefreshLock);
  const refreshLockTimestamp = yield select(selectRefreshLockTimestamp);

  while (refreshLock) {
    if (
      refreshLockTimestamp &&
      new Date().getTime() - refreshLockTimestamp > REFRESH_LOCK_TTL_MILLISECONDS
    ) {
      yield put(setRefreshLock({ refreshLock: false, refreshLockTimestamp: null }));
    }

    yield call(() => new Promise((resolve) => setTimeout(resolve, 100)));
    refreshLock = yield select(selectRefreshLock);
  }
  yield call(attemptTokenRefresh);

  const result = yield call(fn, ...args);
  return result;
}

function* attemptTokenRefresh() {
  try {
    yield put(setRefreshLock({ refreshLock: true, refreshLockTimestamp: new Date().getTime() }));

    const identityStore: IIdentityStore = DI_CONTAINER.get(IDENTITY_STORE_TOKEN);
    const identity = yield call(identityStore.get);

    if (isExpired(identity?.token)) {
      const refreshToken = yield select(selectRefreshToken);
      const resp = yield call(fetchNewToken, refreshToken);

      if (resp.access_token && resp.refresh_token) {
        const authService: IAuthenticateService = DI_CONTAINER.get(AUTHENTICATE_SERVICE_TOKEN);
        const newIdentity = yield call(authService.authenticate, resp.access_token);
        yield call(identityStore.set, newIdentity);
        yield put(setIdentity({ identity: newIdentity }));
        yield put(setRefreshToken({ refreshToken: resp.refresh_token }));
      } else {
        yield put(clear());
        const err = resp?.errors?.length
          ? resp?.errors[0]?.detail?.error_description
          : 'Error refreshing token';
        yield put(
          loginError({
            error: {
              message: err,
              name: 'Error',
            },
          }),
        );
        yield put(push('/getting-started'));
      }
    }
  } catch (e) {
    console.error(e);
  }

  yield put(setRefreshLock({ refreshLock: false, refreshLockTimestamp: null }));
}

async function fetchNewToken(refreshToken: string): Promise<Response> {
  try {
    const oAuthService: IOauthService = DI_CONTAINER.get(OAUTH_SERVICE_TOKEN);
    return await oAuthService.refreshBrandfolderOauthToken(refreshToken);
  } catch (e) {
    getObservabilityService().addError(e);
  }
}

function getDecodedApiKey(key: string): decodedApiKeyProperties {
  return jwtDecode(key);
}

function isExpired(key: string): boolean {
  try {
    const { exp } = getDecodedApiKey(key);
    if (exp && exp - new Date().getTime() / 1000 < SIXTY_SECONDS) {
      return true;
    }
    return false;
  } catch (e) {
    // if decoding fails then we're dealing with an API key, not an oauth token
    return false;
  }
}
